import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { APIService } from './api.service';
import { Request, RequestStatus } from '../models/request.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IQuery } from '../models/query.model';

@Injectable()
export class RequestService extends BaseService<Request>{
    constructor(public apiService: APIService) {
        super(apiService, 'cases', 'cases', '/cases');
    }
    data: Observable<any>;

    getCasesList(query: IQuery = {}): Observable<any>{
        const params = this.getHttpParams(query);

        return this.apiService.get(`${this.resourceURL}`, { params });
    }

    updateStatus(id: number, status: RequestStatus): Observable<Request> {
        const params = Object.assign({ status });

        return this.apiService.put(`${this.resourceURL}/${id}/update_status`, params)
            .pipe(map(data => data[this.single] || data));
    }

    caseCount(query: IQuery = {}): Observable<any>{    
        const params = this.getHttpParams(query);    
        return this.apiService.get(`${this.resourceURL}/case_count`, {params});
    }

    changeCaseType(id: number,  case_type: number): Observable<any>{
        const params = Object.assign({case_type});   
        return this.apiService.post(`${this.resourceURL}/${id}/change_case_type`, params);
    }

    caseCountByDepartment(department:number): Observable<any>{       
        const params = Object.assign({ department});        
        return this.apiService.get(`${this.resourceURL}/case_count`, { params });
    }

    reassignToUser(id:number, assign_to: number): Observable<any>{
        const params = Object.assign({assign_to});

        return this.apiService.post(`${this.resourceURL}/${id}/reassign_user`, params)
            .pipe(map(data => data[this.single] || data))
    }

    reassignToDepartment(id:number, department: number): Observable<any>{
        const params = Object.assign({department});

        return this.apiService.post(`${this.resourceURL}/${id}/reassign_department`, params)
            .pipe(map(data => data[this.single] || data))
    }
}