import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { APIService } from './api.service';
import { CaseRemark } from '../models/case_remark.model';
import { Observable, of } from 'rxjs';
import { IQuery } from '../models/query.model';
import { map } from 'rxjs/operators';

@Injectable()
export class CaseRemarkService extends BaseService<CaseRemark> {
    constructor(public apiService: APIService) {
        super(apiService, 'case-remarks', 'case-remarks', '/case-remarks');
    }

    AddRemark(caseId: number, message: string): Observable<any> {
        const params = Object.assign({ 'case': caseId, 'message': message });

        return this.apiService.post(`${this.resourceURL}`, params)
            .pipe(map(data => data[this.single] || data));
    }

    GetByCase(caseId: number, query: IQuery = {}): Observable<any> {
        const params = Object.assign({ 'case': caseId, query });

        return this.apiService.get(`${this.resourceURL}`, params)
            .pipe(map(data => data[this.single] || data));
    }
    
}
