import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { JWTService } from '../services/jwt.service';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private jwtService: JWTService) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.isAuthenticated$.pipe(map(isAuthenticated => {
            const canActivate = !!(this.jwtService.getToken() && isAuthenticated);
            // console.log('AuthGuard', 'canActivate', canActivate);
            if (!canActivate) {
                this.router.navigate(['/auth/login']);
            }
            return canActivate;
        }));
    }
}
