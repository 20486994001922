export const locale = {
    lang: 'zh',
    data: {
        nav: {
            home: 'Home',
            dashboard: 'Dashboard',
            user: 'User Management',
            ride_task: 'Ride Tasks',
            customer: 'Residence User Registration',
            driver_productivity_report: 'Driver Productivity Report',
        },
    },
};
