import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { APIService } from './api.service';
import { Contacts } from '../models/contacts.model';
import { Observable, of } from 'rxjs';
import { IQuery } from '../models/query.model';

@Injectable()
export class ContactsService extends BaseService<Contacts> {
    constructor(public apiService: APIService) {
        super(apiService, 'contacts', 'contacts', '/contacts');
    }

}