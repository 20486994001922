import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { APIService } from './api.service';
import { webform } from '../models/webform.model';
import { Observable, of } from 'rxjs';
import { IQuery } from '../models/query.model';
import { map } from 'rxjs/operators';

@Injectable()
export class WebformAPIService extends BaseService<webform> {
    constructor(public apiService: APIService) {
        super(apiService, 'webform_api', 'webform_api', '/webform_api');
        
    }
    private headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded'})

    CreateCase(model: webform): Observable<any> {
        const params = Object.assign(model);

        return this.apiService.post(`${this.resourceURL}/create_case`, params)
            .pipe(map(data => data[this.single] || data));
    }

    /* createWebFormBody(model: webform): string {
        let body ="";
        if(model){
            body = `tenantcode=${model.tenantcode}`
        }

        return body
    } */

}