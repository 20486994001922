<form (ngSubmit)="save()" name="form" [formGroup]="form" class="form">
    <mat-form-field appearance="outline">
        <mat-label>{{ 'auth.email' | translate }}</mat-label>
        <input matInput formControlName="email" />
        <mat-icon matSuffix class="secondary-text">mail</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>{{ 'auth.edit_profile.fullname' | translate }}</mat-label>
        <input
            matInput
            name="fullname"
            formControlName="fullname"
            type="text"
            required
        />
        <mat-error *ngIf="form.get('fullname').hasError('required')">
            {{ 'auth.edit_profile.required' | translate }}
        </mat-error>
    </mat-form-field>

    <div class="mb-24 w-100-p">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'auth.edit_profile.ic' | translate }}</mat-label>
            <input matInput formControlName="ic" type="text" />
            <mat-hint>{{ 'auth.edit_profile.ic_hint' | translate }}</mat-hint>
            <mat-error *ngIf="form.get('ic').hasError('required')">
                {{ 'auth.edit_profile.required' | translate }}
            </mat-error>
            <mat-error *ngIf="form.get('ic').hasError('pattern')">
                {{ 'auth.edit_profile.ic_format' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="w-100-p">
        <app-international-phone-input
            [isoFormControl]="form.get('calling_iso')"
            [codeFormControl]="form.get('calling_code')"
        ></app-international-phone-input>
    </div>

    <div class="mb-24 w-100-p">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'auth.edit_profile.contact' | translate }}</mat-label>
            <input matInput formControlName="calling_phone" />
            <mat-hint>{{
                'auth.edit_profile.contact_hint' | translate
            }}</mat-hint>
            <mat-error *ngIf="form.get('calling_phone').hasError('required')">
                {{ 'auth.edit_profile.required' | translate }}
            </mat-error>
            <mat-error *ngIf="form.get('calling_phone').hasError('pattern')">
                {{ 'auth.edit_profile.valid_phone_no_hint' | translate }}
            </mat-error>
            <mat-error
                *ngIf="
                    !form.get('calling_phone').hasError('required') &&
                    !form.get('calling_phone').hasError('pattern') &&
                    form.get('calling_phone').hasError('wrongNumber')
                "
            >
                {{ 'auth.edit_profile.valid_phone_no_hint' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <button
        *ngIf="showSaveButton"
        mat-raised-button
        color="accent"
        class="submit-button"
        aria-label="LOG IN"
        [disabled]="form.invalid"
    >
        {{ 'auth.complete_profile.save' | translate }}
    </button>
</form>
