import { Injectable } from '@angular/core';
import { BaseService } from '../services/base.service';
import { APIService } from '../services/api.service';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IQuery } from '../models/query.model';

@Injectable()
export class UserService extends BaseService<User> {
    constructor(public apiService: APIService) {
        super(apiService, 'user', 'users', '/users');
    }

    leastUserAccount(id: number, kind = 'customer'): Observable<any> {
        return this.apiService.post(
            `${this.resourceURL}/${id}/least_user_account?kind=${kind}`
        );
    }

    updateRole(id: number, user: User): Observable<any> {
        return this.apiService
            .post(`${this.resourceURL}/${id}/update_role`, user)
            .pipe(map((data) => data[this.single]));
    }

    addTag(id: number, tag: string): Observable<any> {
        return this.apiService
            .post(`${this.resourceURL}/${id}/add_tag`, { tag })
            .pipe(map((data) => data[this.single]));
    }

    removeTag(id: number, tag: string): Observable<any> {
        return this.apiService
            .post(`${this.resourceURL}/${id}/remove_tag`, { tag })
            .pipe(map((data) => data[this.single]));
    }

    residentExport(query: IQuery = {}, filename?: string): Observable<any> {
        const params = this.getHttpParams(query);
        return this.apiService.download(`${this.resourceURL}/resident_export`, params, filename);
    }

    getByDepartment(department): Observable<any> {
        const params = Object.assign({ department });
        return this.apiService.get(`${this.resourceURL}?`,{ params })
            .pipe(map(data => data[this.single] || data));
    }
}
