import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { APIService } from '@app/core/services/api.service';
import { Feature } from '../models/feature.model';

@Injectable()
export class FeatureService extends BaseService<Feature> {
    constructor(public apiService: APIService) {
        super(apiService, 'feature', 'features', '/features');
    }
}
