import { HttpParams } from '@angular/common/http';
import { APIService } from '../services/api.service';
import { map, tap } from 'rxjs/operators';
import { of, Observable, Subject } from 'rxjs';
import { IQuery } from '../models/query.model';

export interface IService<TModel> {
    single: string;
    plural: string;
    list(query: IQuery, routeUrl?: string): Observable<any>;
    one(id, query: IQuery): Observable<any>;
    add(model: TModel): Observable<TModel>;
    update(id, model: TModel, routeUrl?: string): Observable<TModel>;
    delete(id): Observable<any>;
    save(model, id): Observable<TModel>;
}

// @Injectable()
export class BaseService<TModel> implements IService<TModel> {
    protected addedSubject = new Subject<any>();
    public added$ = this.addedSubject.asObservable();
    protected updatedSubject = new Subject<any>();
    public updated$ = this.updatedSubject.asObservable();
    protected deletedSubject = new Subject<any>();
    public deleted$ = this.deletedSubject.asObservable();

    constructor(
        public apiService: APIService,
        public single: string,
        public plural: string,
        protected resourceURL: string
    ) {}

    list(query: IQuery = {}, routeUrl?: string): Observable<any> {
        const url = routeUrl ? this.resourceURL + routeUrl : this.resourceURL;
        const params = this.getHttpParams(query);

        return this.apiService.get(url, { params });
    }

    one(id, query: IQuery = {}): Observable<any> {
        if (!id) {
            return of({});
        }

        const params = this.getHttpParams(query);

        return this.apiService.get(this.resourceURL + '/' + id, { params });
    }

    add(model: TModel): Observable<TModel> {
        return this.apiService.post(this.resourceURL, model).pipe(
            map((data) => data[this.single] || data),
            tap((data) => this.addedSubject.next(data))
        );
    }

    update(id, model: TModel, routeUrl?: string): Observable<TModel> {
        const url = routeUrl
            ? this.resourceURL + '/' + id + routeUrl
            : this.resourceURL + '/' + id;

        return this.apiService.put(url, model).pipe(
            map((data) => data[this.single] || data),
            tap((data) => this.updatedSubject.next(data))
        );
    }

    save(model: TModel, id?: number): Observable<TModel> {
        return id ? this.update(id, model) : this.add(model);
    }

    delete(id): Observable<any> {
        return this.apiService
            .delete(this.resourceURL + '/' + id)
            .pipe(tap((data) => this.deletedSubject.next(id)));
    }

    getHttpParams(query: IQuery): HttpParams {
        let httpParams = new HttpParams();

        // Meta
        if (query.meta) {
            Object.keys(query.meta).forEach((key) => {
                httpParams = httpParams.set(key, query.meta[key]);
            });
        }

        // Sort
        if (query.sort) {
            httpParams = httpParams.set('sort[]', query.sort);
        }

        // Match
        if (query.match) {
            query.match.forEach((value, key) => {
                if (value || value === 0 || value === false) {
                    httpParams = httpParams.set(`filter{${key}}`, value);
                }
            });
        }

        // Contains
        if (query.contains) {
            query.contains.forEach((value, key) => {
                if (value) {
                    httpParams = httpParams.set(
                        `filter{${key}.icontains}`,
                        value
                    );
                }
            });
        }

        // Start With
        if (query.start_with) {
            query.start_with.forEach((value, key) => {
                if (value) {
                    httpParams = httpParams.set(
                        `filter{${key}.istartswith}`,
                        value
                    );
                }
            });
        }

        // lt
        if (query.lt) {
            query.lt.forEach((value, key) => {
                if (value) {
                    httpParams = httpParams.set(`filter{${key}.lt}`, value);
                }
            });
        }

        // gt
        if (query.gt) {
            query.gt.forEach((value, key) => {
                if (value) {
                    httpParams = httpParams.set(`filter{${key}.gt}`, value);
                }
            });
        }

        // filter
        if (query.filter) {
            query.filter.forEach((value, key) => {
                if (is_valid(value)) {
                    httpParams = httpParams.set(`filter{${key}}`, value);
                }
            });
        }

        // Include
        if (query.include) {
            query.include.forEach((attribute) => {
                httpParams = httpParams.append('include[]', attribute);
            });
        }

        // Exclude
        if (query.exclude) {
            query.exclude.forEach((attribute) => {
                httpParams = httpParams.append('include[]', attribute);
            });
        }

        // Params
        if (query.params) {
            query.params.forEach((value, key) => {
                if (is_valid(value)) {
                    httpParams = httpParams.set(key, value);
                }
            });
        }

        // In
        if (query.in) {
            query.in.forEach((values, key) => {
                values.forEach((value) => {
                    if (is_valid(value)) {
                        httpParams = httpParams.append(
                            `filter{${key}.in}`,
                            value
                        );
                    }
                });
            });
        }

        // Is Null
        if (query.is_null) {
            query.is_null.forEach((value, key) => {
                httpParams = httpParams.set(
                    `filter{${key}.isnull}`,
                    value ? '1' : '0'
                );
            });
        }

        // Append
        if (query.append) {
            query.append.forEach((attribute) => {
                httpParams = httpParams.append(attribute, '');
            });
        }

        function is_valid(value) {
            if (value === undefined) {
                return false;
            }

            if (typeof value === 'string') {
                return value.length > 0;
            }

            if (typeof value === 'number') {
                return true;
            }

            if (typeof value === 'boolean') {
                return true;
            }

            if (typeof value === 'boolean') {
                return true;
            }

            if (Array.isArray(value)) {
                return true;
            }

            console.error('unknown:', typeof value);
            return false;
        }

        return httpParams;
    }
}
