import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { APIService } from './api.service';
import { Account } from '../models/account.model';
import { Observable, of } from 'rxjs';
import { IQuery } from '../models/query.model';

@Injectable()
export class AccountsService extends BaseService<Account> {
    constructor(public apiService: APIService) {
        super(apiService, 'accounts', 'accounts', '/accounts');
    }

}