import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { SelectOnFocusDirective } from './directives/select-on-focus.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { QuantityFormatPipe } from './pipes/quantity-format.pipe';
import { QuantityInputFormatterDirective } from './directives/quantity-input-formatter.directive';
import { CurrencyInputFormatterDirective } from './directives/currency-input-formatter.directive';
import { HighlightDirective } from './directives/highlight.directive';
import { ReadyButtonDirective } from './directives/ready-button.directive';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { NumericDirective } from './directives/numeric.directive';
import { InternationalPhoneInputComponent } from './components/international-phone-input/international-phone-input.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSelectModule,
        MatToolbarModule,
        MatDialogModule,
        MatSnackBarModule,

        NgxMatSelectSearchModule,

        TranslateModule,
        FuseSharedModule
    ],
    declarations: [
        DateFormatPipe,
        DateTimeFormatPipe,
        SelectOnFocusDirective,
        CurrencyFormatPipe,
        QuantityFormatPipe,
        QuantityInputFormatterDirective,
        CurrencyInputFormatterDirective,
        HighlightDirective,
        ReadyButtonDirective,
        NumericDirective,
        ConfirmDialogComponent,
        InternationalPhoneInputComponent
    ],
    exports: [
        DateFormatPipe,
        DateTimeFormatPipe,
        CurrencyFormatPipe,
        QuantityFormatPipe,
        SelectOnFocusDirective,
        QuantityInputFormatterDirective,
        CurrencyInputFormatterDirective,
        HighlightDirective,
        ReadyButtonDirective,
        NumericDirective,
        InternationalPhoneInputComponent
    ],
    entryComponents: [
        ConfirmDialogComponent
    ]
})
export class SharedModule { }
