import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { MatColors } from '@fuse/mat-colors';


@Directive({
    selector: '[appHighlight]'
})
export class HighlightDirective implements OnChanges {
    @Input('appHighlight')
    appHighlight: boolean;

    @Input('appHighlightColor')
    appHighlightColor: string; // see @fuse/mat-colors

    defaultColor = MatColors.getColor('grey');

    constructor(private el: ElementRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.appHighlight) {
            const color = MatColors.getColor(this.appHighlightColor)
                ? MatColors.getColor(this.appHighlightColor)
                : this.defaultColor;

            this.el.nativeElement.style.color = color['700'];
        } else {
            this.el.nativeElement.style.color = 'inherit';
        }
    }

}
