import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as en } from './i18n/en';
import { locale as zh } from './i18n/zh';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    version: string = environment.version;

    /**
     * Constructor
     */
    constructor(
        private fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this.fuseTranslationLoaderService.loadTranslations(en, zh);
    }
}
