import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password1 = control.parent.get('new_password1');
    const password2 = control.parent.get('new_password2');

    if (!password1 || !password2) {
        return null;
    }

    if (password2.value === '') {
        return null;
    }

    if (password1.value === password2.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};