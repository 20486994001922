<div class="dialog-content-wrapper">
    <mat-toolbar class="fuse-white m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ 'auth.change_password.title' | translate }}</span>
            <button color="primary" mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>

        <form name="form" [formGroup]="form" class="form" fxLayout="column" fxFlex fxLayoutGap="8px">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'auth.change_password.old_password' | translate }}</mat-label>
                <input matInput type="password" formControlName="old_password" required>
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error *ngIf="form.get('old_password').hasError('required')">
                    {{ 'auth.password_required' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'auth.reset_password.new_password1' | translate }}</mat-label>
                <input matInput type="password" formControlName="new_password1" required>
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error *ngIf="form.get('new_password1').hasError('required')">
                    {{ 'auth.password_required' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('new_password1').hasError('minlength')">
                    {{ 'auth.reset_password.password_minlength' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'auth.reset_password.new_password2' | translate }}</mat-label>
                <input matInput type="password" formControlName="new_password2" required>
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error *ngIf="form.get('new_password2').hasError('required')">
                    {{ 'auth.password_required' | translate }}
                </mat-error>
                <mat-error *ngIf="!form.get('new_password2').hasError('required') &&
                    form.get('new_password2').hasError('passwordsNotMatching')">
                    {{ 'auth.reset_password.password_mismatch' | translate }}
                </mat-error>
            </mat-form-field>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-24" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="24px">

        <button mat-stroked-button type="button" color="primary" (click)="matDialogRef.close()" aria-label="Cancel">
            {{ 'auth.change_password.cancel' | translate }}
        </button>

        <button mat-flat-button color="accent" (click)="save()" [disabled]="form.pristine || form.invalid"
            aria-label="Save">
            {{ 'auth.change_password.save' | translate }}
        </button>
    </div>
</div>