<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/LAKLGCC_logo_gold_ver.png">
            </div>

            <div class="app-name">
                {{ environment.appName }}
            </div>

            <div class="title">{{ 'auth.forgot_password.title' | translate }}</div>

            <form name="forgotPasswordForm" [formGroup]="form" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'auth.email' | translate }}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="form.get('email').hasError('required')">
                        {{ 'auth.email_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('email').hasError('email')">
                        {{ 'auth.enter_valid_email' | translate }}
                    </mat-error>
                </mat-form-field>
            </form>

            <div class="login" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
                <mat-bar-button (click)="send()" [options]="buttonOption"></mat-bar-button>
                <a class="link" [routerLink]="'/auth/login'">{{ 'auth.forgot_password.go_back_login' | translate }}</a>
            </div>

        </div>

    </div>

</div>