// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    version: '1.0',
    production: true,
    hmr: false,
    apiUrl: 'https://crm-api.klgcc.com/v1',
    pageSize: 10,
    dateFormatPipe: 'yyyy-MM-dd', // https://angular.io/api/common/DatePipe
    dateTimeFormatPipe: 'yyyy-MM-dd HH:mm', // https://angular.io/api/common/DatePipe
    dateFormatPicker: 'Y-MM-DD', // https://momentjs.com/docs/#/displaying/format/
    dateTimeFormatPicker: 'Y-MM-DD HH:mm', // https://momentjs.com/docs/#/displaying/format/
    currencyFormatPipe: '1.2-2', // https://angular.io/api/common/DecimalPipe
    quantityFormatPipe: '1.0-0',
    multiTenant: false, // set apiUrl to api/v1 if true
    appName: 'KLGCC CRM',
    firebase: {
        apiKey: '',
        authDomain: '',
        databaseURL: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: '',
        measurementId: '',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
