import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { APIService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IQuery } from '../models/query.model';

@Injectable()
export class ReportService extends BaseService<any> {
    constructor(public apiService: APIService) {
        super(apiService, '', '', '/report');
    }

    driverProductivity(query: IQuery = {}): Observable<any> {
        const params = this.getHttpParams(query);

        return this.apiService.get(`${this.resourceURL}/driver_productivity`, {
            params,
        });
    }
}
