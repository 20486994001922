import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'home',
        feature: 'home',
        title: 'Home',
        translate: 'nav.home',
        type: 'item',
        icon: 'home',
        url: '/home',
    },
    /* {
        id: 'user',
        feature: 'user',
        title: 'User Management',
        translate: 'nav.user',
        type: 'item',
        icon: 'table_chart',
        url: '/customers',
    }, */
    {
        id: 'account',
        feature: 'account',
        title: 'Account Management',
        translate: 'nav.account',
        type: 'item',
        icon: 'account_circle',
        url: '/accounts',
    },
    {
        id: 'case',
        feature: 'case',
        title: 'Requests',
        translate: 'nav.request',
        type: 'item',
        icon: 'view_list',
        url: '/request',
    },
    /* {
        id: 'driver-productivity-report',
        feature: 'driver-productivity-report',
        title: 'Driver Productivity Report',
        translate: 'nav.driver_productivity_report',
        type: 'item',
        icon: 'table_chart',
        url: '/driver-productivity-report',
    }, */
];
