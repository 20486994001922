export const locale = {
    lang: 'en',
    data: {
        toolbar: {
            profile: 'Profile',
            change_password: 'Password',
            logout: 'Logout',
            view_checkout_page: 'View Public Checkout Page'
        },
        user_role_options: {
            management: 'Management',
            unithead: 'Business Unit Head',
            manager: 'Manager',
            executive: 'Executive',
            customer: 'Customer'
        },
    }
};
