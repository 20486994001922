export const locale = {
    lang: 'zh',
    data: {
        toolbar: {
            profile: '帐号管理',
            change_password: '重置密码',
            logout: '退出登录',
            view_checkout_page: '查看公开结账页面'
        },
        user_role_options: {
            management: 'Management',
            unithead: 'Business Unit Head',
            manager: '经理',
            executive: '主管',
            customer: '顾客'
        },
    }
};
