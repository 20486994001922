import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { CountryMap, CountryList } from './country';
import { startWith, debounceTime, switchMap } from 'rxjs/operators';
import { locale as en } from './i18n/en';
import { locale as zh } from './i18n/zh';

import * as _ from 'lodash';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
    selector: 'app-international-phone-input',
    templateUrl: './international-phone-input.component.html',
    styleUrls: ['./international-phone-input.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InternationalPhoneInputComponent implements OnInit {
    readonly CountryList = CountryList;
    readonly CountryMap = CountryMap;

    @Input()
    isoFormControl: FormControl;
    @Input()
    codeFormControl: FormControl;
    @Input()
    readonly = false;

    countryFilterForm: FormGroup;
    countryOptions$: Observable<any[]>;

    constructor(
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        private formBuilder: FormBuilder
    ) {
        this.fuseTranslationLoaderService.loadTranslations(en, zh);
        this.countryFilterForm = this.formBuilder.group({ filter: [''] });
    }

    ngOnInit(): void {
        this.isoFormControl.valueChanges
            .subscribe(value => {
                this.codeFormControl.patchValue(value && this.CountryMap[value] ? this.CountryMap[value].calling_code : '');
            });

        this.countryOptions$ = this.countryFilterForm
            .get('filter')
            .valueChanges.pipe(
                startWith(''),
                debounceTime(300),
                switchMap(value => {
                    let filter = [];

                    if (this.readonly) {
                        filter = _.filter(CountryList, item => item.iso === this.isoFormControl.value);
                    } else {
                        filter = _.filter(CountryList, item => _.includes(item.name.toLowerCase(), value.toLowerCase()));;
                    }

                    return of(_.sortBy(filter, 'name'));
                })
            );
    }

}
