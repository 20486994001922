export const locale = {
    lang: 'zh',
    data: {
        auth: {
            email: '邮箱',
            password: '密码',
            email_required: '请输入邮箱',
            enter_valid_email: '请输入有效的邮箱',
            password_required: '请输入密码',
            login: {
                title: '登录帐号',
                remember_me: '保持登录',
                forgot_password: '忘记密码?',
                login_button: '登录'
            },
            forgot_password: {
                title: '忘记密码',
                send_reset_link_button: '发送',
                go_back_login: '回到登录',
                success_send: '成功发送重设密码电子邮件'
            },
            reset_password: {
                title: '重设密码',
                new_password1: '新密码',
                new_password2: '确定新密码',
                password_minlength: '最少6个字符',
                password_mismatch: '密码不匹配',
                send: '重设密码',
                go_back_login: '回到登录',
                success_send: '成功重设密码',
                invalid_token_or_uid: '重设密码无效'
            },
            change_password: {
                title: '重置密码',
                old_password: '旧密码',
                new_password1: '新密码',
                new_password2: '确定新密码',
                required: '必填项目',
                password_minlength: '最少6个字符',
                password_mismatch: '密码不匹配',
                save: '发送',
                cancel: '取消',
                success_update: '成功重置密码',
                invalid_old_password: '旧密码出错'
            },
            edit_profile: {
                title: '帐号管理',
                first_name: '名称1',
                last_name: '名称2',
                first_name_required: '请输入名称1',
                fullname: '名称',
                fullname_required: '请输入名称',
                save: '更新资料',
                cancel: '取消',
                success_update: '成功更新资料',
                calling_code: 'Country Code',
                contact: '通讯号码',
                ic: '身份证/护照号码',
                ic_hint: 'e.g. 801010-07-1234',
                ic_length_hint: '12位数',
                ic_format: '请输入一个有效的身份证号码',
                contact_hint: 'e.g. 163728908',
                valid_phone_no_hint: '请输入一个有效的电话号码',
                required: '必填项目',
                search_country: 'Search Country',
                no_result: 'No Result'
            },
            complete_profile: {
                title: 'COMPLETE PROFILE',
                save: 'SAVE'
            },
            activate_account: {
                title: 'ACTIVATE YOUR ACCOUNT',
                activate: 'ACTIVATE',
                success_activate: 'Account successfully activate',
                expired: 'Activation is Expired',
                go_back_login: 'Go back to login',
            }
        }
    }
};
