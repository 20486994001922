import * as _ from 'lodash';

export const CountryList = [
    { iso: 'AW', name: 'Aruba', flag: '\ud83c\udde6\ud83c\uddfc', calling_code: '297' },
    { iso: 'AF', name: 'Islamic State of Afghanistan', flag: '\ud83c\udde6\ud83c\uddeb', calling_code: '93' },
    { iso: 'AO', name: 'People\'s Republic of Angola', flag: '\ud83c\udde6\ud83c\uddf4', calling_code: '244' },
    { iso: 'AX', name: 'Åland Islands', flag: '\ud83c\udde6\ud83c\uddfd', calling_code: '358' },
    { iso: 'AL', name: 'Republic of Albania', flag: '\ud83c\udde6\ud83c\uddf1', calling_code: '355' },
    { iso: 'AD', name: 'Principality of Andorra', flag: '\ud83c\udde6\ud83c\udde9', calling_code: '376' },
    { iso: 'AE', name: 'United Arab Emirates', flag: '\ud83c\udde6\ud83c\uddea', calling_code: '971' },
    { iso: 'AR', name: 'Argentine Republic', flag: '\ud83c\udde6\ud83c\uddf7', calling_code: '54' },
    { iso: 'AM', name: 'Republic of Armenia', flag: '\ud83c\udde6\ud83c\uddf2', calling_code: '374' },
    { iso: 'AS', name: 'American Samoa', flag: '\ud83c\udde6\ud83c\uddf8', calling_code: '1684' },
    { iso: 'AG', name: 'Antigua and Barbuda', flag: '\ud83c\udde6\ud83c\uddec', calling_code: '1268' },
    { iso: 'AU', name: 'Commonwealth of Australia', flag: '\ud83c\udde6\ud83c\uddfa', calling_code: '61' },
    { iso: 'AT', name: 'Republic of Austria', flag: '\ud83c\udde6\ud83c\uddf9', calling_code: '43' },
    { iso: 'AZ', name: 'Republic of Azerbaijan', flag: '\ud83c\udde6\ud83c\uddff', calling_code: '994' },
    { iso: 'BI', name: 'Republic of Burundi', flag: '\ud83c\udde7\ud83c\uddee', calling_code: '257' },
    { iso: 'BE', name: 'Kingdom of Belgium', flag: '\ud83c\udde7\ud83c\uddea', calling_code: '32' },
    { iso: 'BJ', name: 'Republic of Benin', flag: '\ud83c\udde7\ud83c\uddef', calling_code: '229' },
    { iso: 'BF', name: 'Burkina Faso', flag: '\ud83c\udde7\ud83c\uddeb', calling_code: '226' },
    { iso: 'BD', name: 'People\'s Republic of Bangladesh', flag: '\ud83c\udde7\ud83c\udde9', calling_code: '880' },
    { iso: 'BG', name: 'Republic of Bulgaria', flag: '\ud83c\udde7\ud83c\uddec', calling_code: '359' },
    { iso: 'BH', name: 'Kingdom of Bahrain', flag: '\ud83c\udde7\ud83c\udded', calling_code: '973' },
    { iso: 'BS', name: 'Commonwealth of the Bahamas', flag: '\ud83c\udde7\ud83c\uddf8', calling_code: '1242' },
    { iso: 'BA', name: 'Bosnia and Herzegovina', flag: '\ud83c\udde7\ud83c\udde6', calling_code: '387' },
    { iso: 'BL', name: 'Saint-Barth\u00c3\u00a9lemy', flag: '\ud83c\udde7\ud83c\uddf1', calling_code: '590' },
    { iso: 'BY', name: 'Republic of Belarus', flag: '\ud83c\udde7\ud83c\uddfe', calling_code: '375' },
    { iso: 'BZ', name: 'Belize', flag: '\ud83c\udde7\ud83c\uddff', calling_code: '501' },
    { iso: 'BM', name: 'The Bermudas or Somers Isles', flag: '\ud83c\udde7\ud83c\uddf2', calling_code: '1441' },
    { iso: 'BO', name: 'Plurinational State of Bolivia', flag: '\ud83c\udde7\ud83c\uddf4', calling_code: '591' },
    { iso: 'BR', name: 'Federative Republic of Brazil', flag: '\ud83c\udde7\ud83c\uddf7', calling_code: '55' },
    { iso: 'BB', name: 'Barbados', flag: '\ud83c\udde7\ud83c\udde7', calling_code: '1246' },
    { iso: 'BN', name: 'Negara Brunei Darussalam', flag: '\ud83c\udde7\ud83c\uddf3', calling_code: '673' },
    { iso: 'BT', name: 'Kingdom of Bhutan', flag: '\ud83c\udde7\ud83c\uddf9', calling_code: '975' },
    { iso: 'BW', name: 'Republic of Botswana', flag: '\ud83c\udde7\ud83c\uddfc', calling_code: '267' },
    { iso: 'CF', name: 'Central African Republic', flag: '\ud83c\udde8\ud83c\uddeb', calling_code: '236' },
    { iso: 'CA', name: 'Canada', flag: '\ud83c\udde8\ud83c\udde6', calling_code: '1' },
    { iso: 'CH', name: 'Swiss Confederation', flag: '\ud83c\udde8\ud83c\udded', calling_code: '41' },
    { iso: 'CL', name: 'Republic of Chile', flag: '\ud83c\udde8\ud83c\uddf1', calling_code: '56' },
    { iso: 'CN', name: 'People\'s Republic of China', flag: '\ud83c\udde8\ud83c\uddf3', calling_code: '86' },
    { iso: 'CI', name: 'Republic of Ivory Coast', flag: '\ud83c\udde8\ud83c\uddee', calling_code: '225' },
    { iso: 'CM', name: 'Republic of Cameroon', flag: '\ud83c\udde8\ud83c\uddf2', calling_code: '237' },
    { iso: 'CD', name: 'Democratic Republic of the Congo', flag: '\ud83c\udde8\ud83c\udde9', calling_code: '243' },
    { iso: 'CG', name: 'Republic of the Congo', flag: '\ud83c\udde8\ud83c\uddec', calling_code: '242' },
    { iso: 'CO', name: 'Republic of Colombia', flag: '\ud83c\udde8\ud83c\uddf4', calling_code: '57' },
    { iso: 'KM', name: 'Union of the Comoros', flag: '\ud83c\uddf0\ud83c\uddf2', calling_code: '269' },
    { iso: 'CV', name: 'Republic of Cabo Verde', flag: '\ud83c\udde8\ud83c\uddfb', calling_code: '238' },
    { iso: 'CR', name: 'Republic of Costa Rica', flag: '\ud83c\udde8\ud83c\uddf7', calling_code: '506' },
    { iso: 'CU', name: 'Republic of Cuba', flag: '\ud83c\udde8\ud83c\uddfa', calling_code: '53' },
    { iso: 'CW', name: 'Curaçao', flag: '\ud83c\udde8\ud83c\uddfc', calling_code: '599' },
    { iso: 'KY', name: 'Cayman Islands', flag: '\ud83c\uddf0\ud83c\uddfe', calling_code: '1345' },
    { iso: 'CY', name: 'Republic of Cyprus', flag: '\ud83c\udde8\ud83c\uddfe', calling_code: '357' },
    { iso: 'CZ', name: 'Czech Republic', flag: '\ud83c\udde8\ud83c\uddff', calling_code: '420' },
    { iso: 'DE', name: 'Federal Republic of Germany', flag: '\ud83c\udde9\ud83c\uddea', calling_code: '49' },
    { iso: 'DJ', name: 'Republic of Djibouti', flag: '\ud83c\udde9\ud83c\uddef', calling_code: '253' },
    { iso: 'DM', name: 'Commonwealth of Dominica', flag: '\ud83c\udde9\ud83c\uddf2', calling_code: '1767' },
    { iso: 'DK', name: 'Kingdom of Denmark', flag: '\ud83c\udde9\ud83c\uddf0', calling_code: '45' },
    { iso: 'DO', name: 'Dominican Republic', flag: '\ud83c\udde9\ud83c\uddf4', calling_code: '1809' },
    { iso: 'DZ', name: 'People\'s Democratic Republic of Algeria', flag: '\ud83c\udde9\ud83c\uddff', calling_code: '213' },
    { iso: 'EC', name: 'Republic of Ecuador', flag: '\ud83c\uddea\ud83c\udde8', calling_code: '593' },
    { iso: 'EG', name: 'Arab Republic of Egypt', flag: '\ud83c\uddea\ud83c\uddec', calling_code: '20' },
    { iso: 'ER', name: 'State of Eritrea', flag: '\ud83c\uddea\ud83c\uddf7', calling_code: '291' },
    { iso: 'EH', name: 'Sahrawi Arab Democratic Republic', flag: '\ud83c\uddea\ud83c\udded', calling_code: '212' },
    { iso: 'ES', name: 'Kingdom of Spain', flag: '\ud83c\uddea\ud83c\uddf8', calling_code: '34' },
    { iso: 'EE', name: 'Republic of Estonia', flag: '\ud83c\uddea\ud83c\uddea', calling_code: '372' },
    { iso: 'ET', name: 'Federal Democratic Republic of Ethiopia', flag: '\ud83c\uddea\ud83c\uddf9', calling_code: '251' },
    { iso: 'FI', name: 'Republic of Finland', flag: '\ud83c\uddeb\ud83c\uddee', calling_code: '358' },
    { iso: 'FJ', name: 'Republic of Fiji', flag: '\ud83c\uddeb\ud83c\uddef', calling_code: '679' },
    { iso: 'FK', name: 'Falkland Islands', flag: '\ud83c\uddeb\ud83c\uddf0', calling_code: '500' },
    { iso: 'FR', name: 'French Republic', flag: '\ud83c\uddeb\ud83c\uddf7', calling_code: '33' },
    { iso: 'FO', name: 'Føroyar Is. (Faeroe Is.)', flag: '\ud83c\uddeb\ud83c\uddf4', calling_code: '298' },
    { iso: 'FM', name: 'Federated States of Micronesia', flag: '\ud83c\uddeb\ud83c\uddf2', calling_code: '691' },
    { iso: 'GA', name: 'Gabonese Republic', flag: '\ud83c\uddec\ud83c\udde6', calling_code: '241' },
    { iso: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland', flag: '\ud83c\uddec\ud83c\udde7', calling_code: '44' },
    { iso: 'GE', name: 'Georgia', flag: '\ud83c\uddec\ud83c\uddea', calling_code: '995' },
    { iso: 'GG', name: 'Bailiwick of Guernsey', flag: '\ud83c\uddec\ud83c\uddec', calling_code: '44' },
    { iso: 'GH', name: 'Republic of Ghana', flag: '\ud83c\uddec\ud83c\udded', calling_code: '233' },
    { iso: 'GN', name: 'Republic of Guinea', flag: '\ud83c\uddec\ud83c\uddf3', calling_code: '224' },
    { iso: 'GM', name: 'Republic of the Gambia', flag: '\ud83c\uddec\ud83c\uddf2', calling_code: '220' },
    { iso: 'GW', name: 'Republic of Guinea-Bissau', flag: '\ud83c\uddec\ud83c\uddfc', calling_code: '245' },
    { iso: 'GQ', name: 'Republic of Equatorial Guinea', flag: '\ud83c\uddec\ud83c\uddf6', calling_code: '240' },
    { iso: 'GR', name: 'Hellenic Republic', flag: '\ud83c\uddec\ud83c\uddf7', calling_code: '30' },
    { iso: 'GD', name: 'Grenada', flag: '\ud83c\uddec\ud83c\udde9', calling_code: '1473' },
    { iso: 'GL', name: 'Greenland', flag: '\ud83c\uddec\ud83c\uddf1', calling_code: '299' },
    { iso: 'GT', name: 'Republic of Guatemala', flag: '\ud83c\uddec\ud83c\uddf9', calling_code: '502' },
    { iso: 'GU', name: 'Territory of Guam', flag: '\ud83c\uddec\ud83c\uddfa', calling_code: '1671' },
    { iso: 'GY', name: 'Co-operative Republic of Guyana', flag: '\ud83c\uddec\ud83c\uddfe', calling_code: '592' },
    { iso: 'HK', name: 'Hong Kong Special Administrative Region, PRC', flag: '\ud83c\udded\ud83c\uddf0', calling_code: '852' },
    { iso: 'HN', name: 'Republic of Honduras', flag: '\ud83c\udded\ud83c\uddf3', calling_code: '504' },
    { iso: 'HR', name: 'Republic of Croatia', flag: '\ud83c\udded\ud83c\uddf7', calling_code: '385' },
    { iso: 'HT', name: 'Republic of Haiti', flag: '\ud83c\udded\ud83c\uddf9', calling_code: '509' },
    { iso: 'HU', name: 'Republic of Hungary', flag: '\ud83c\udded\ud83c\uddfa', calling_code: '36' },
    { iso: 'ID', name: 'Republic of Indonesia', flag: '\ud83c\uddee\ud83c\udde9', calling_code: '62' },
    { iso: 'IN', name: 'Republic of India', flag: '\ud83c\uddee\ud83c\uddf3', calling_code: '91' },
    { iso: 'IE', name: 'Ireland', flag: '\ud83c\uddee\ud83c\uddea', calling_code: '353' },
    { iso: 'IR', name: 'Islamic Republic of Iran', flag: '\ud83c\uddee\ud83c\uddf7', calling_code: '98' },
    { iso: 'IQ', name: 'Republic of Iraq', flag: '\ud83c\uddee\ud83c\uddf6', calling_code: '964' },
    { iso: 'IS', name: 'Republic of Iceland', flag: '\ud83c\uddee\ud83c\uddf8', calling_code: '354' },
    { iso: 'IL', name: 'State of Israel', flag: '\ud83c\uddee\ud83c\uddf1', calling_code: '972' },
    { iso: 'IT', name: 'Italian Republic', flag: '\ud83c\uddee\ud83c\uddf9', calling_code: '39' },
    { iso: 'JM', name: 'Jamaica', flag: '\ud83c\uddef\ud83c\uddf2', calling_code: '1876' },
    { iso: 'JE', name: 'Bailiwick of Jersey', flag: '\ud83c\uddef\ud83c\uddea', calling_code: '44' },
    { iso: 'JO', name: 'Hashemite Kingdom of Jordan', flag: '\ud83c\uddef\ud83c\uddf4', calling_code: '962' },
    { iso: 'JP', name: 'Japan', flag: '\ud83c\uddef\ud83c\uddf5', calling_code: '81' },
    { iso: 'KZ', name: 'Republic of Kazakhstan', flag: '\ud83c\uddf0\ud83c\uddff', calling_code: '76' },
    { iso: 'KE', name: 'Republic of Kenya', flag: '\ud83c\uddf0\ud83c\uddea', calling_code: '254' },
    { iso: 'KG', name: 'Kyrgyz Republic', flag: '\ud83c\uddf0\ud83c\uddec', calling_code: '996' },
    { iso: 'KH', name: 'Kingdom of Cambodia', flag: '\ud83c\uddf0\ud83c\udded', calling_code: '855' },
    { iso: 'KI', name: 'Republic of Kiribati', flag: '\ud83c\uddf0\ud83c\uddee', calling_code: '686' },
    { iso: 'KN', name: 'Federation of Saint Kitts and Nevis', flag: '\ud83c\uddf0\ud83c\uddf3', calling_code: '1869' },
    { iso: 'KR', name: 'Republic of Korea', flag: '\ud83c\uddf0\ud83c\uddf7', calling_code: '82' },
    { iso: 'XK', name: 'Republic of Kosovo', flag: '\ud83c\uddfd\ud83c\uddf0', calling_code: '383' },
    { iso: 'KW', name: 'State of Kuwait', flag: '\ud83c\uddf0\ud83c\uddfc', calling_code: '965' },
    { iso: 'LA', name: 'Lao People\'s Democratic Republic', flag: '\ud83c\uddf1\ud83c\udde6', calling_code: '856' },
    { iso: 'LB', name: 'Lebanese Republic', flag: '\ud83c\uddf1\ud83c\udde7', calling_code: '961' },
    { iso: 'LR', name: 'Republic of Liberia', flag: '\ud83c\uddf1\ud83c\uddf7', calling_code: '231' },
    { iso: 'LY', name: 'Libya', flag: '\ud83c\uddf1\ud83c\uddfe', calling_code: '218' },
    { iso: 'LC', name: 'Saint Lucia', flag: '\ud83c\uddf1\ud83c\udde8', calling_code: '1758' },
    { iso: 'LI', name: 'Principality of Liechtenstein', flag: '\ud83c\uddf1\ud83c\uddee', calling_code: '423' },
    { iso: 'LK', name: 'Democratic Socialist Republic of Sri Lanka', flag: '\ud83c\uddf1\ud83c\uddf0', calling_code: '94' },
    { iso: 'LS', name: 'Kingdom of Lesotho', flag: '\ud83c\uddf1\ud83c\uddf8', calling_code: '266' },
    { iso: 'LT', name: 'Republic of Lithuania', flag: '\ud83c\uddf1\ud83c\uddf9', calling_code: '370' },
    { iso: 'LU', name: 'Grand Duchy of Luxembourg', flag: '\ud83c\uddf1\ud83c\uddfa', calling_code: '352' },
    { iso: 'LV', name: 'Republic of Latvia', flag: '\ud83c\uddf1\ud83c\uddfb', calling_code: '371' },
    { iso: 'MO', name: 'Macao Special Administrative Region, PRC', flag: '\ud83c\uddf2\ud83c\uddf4', calling_code: '853' },
    { iso: 'MF', name: 'Saint-Martin (French part)', flag: '\ud83c\uddf2\ud83c\uddeb', calling_code: '590' },
    { iso: 'MA', name: 'Kingdom of Morocco', flag: '\ud83c\uddf2\ud83c\udde6', calling_code: '212' },
    { iso: 'MC', name: 'Principality of Monaco', flag: '\ud83c\uddf2\ud83c\udde8', calling_code: '377' },
    { iso: 'MD', name: 'Republic of Moldova', flag: '\ud83c\uddf2\ud83c\udde9', calling_code: '373' },
    { iso: 'MG', name: 'Republic of Madagascar', flag: '\ud83c\uddf2\ud83c\uddec', calling_code: '261' },
    { iso: 'MV', name: 'Republic of Maldives', flag: '\ud83c\uddf2\ud83c\uddfb', calling_code: '960' },
    { iso: 'MX', name: 'United Mexican States', flag: '\ud83c\uddf2\ud83c\uddfd', calling_code: '52' },
    { iso: 'MH', name: 'Republic of the Marshall Islands', flag: '\ud83c\uddf2\ud83c\udded', calling_code: '692' },
    { iso: 'MK', name: 'Former Yugoslav Republic of Macedonia', flag: '\ud83c\uddf2\ud83c\uddf0', calling_code: '389' },
    { iso: 'ML', name: 'Republic of Mali', flag: '\ud83c\uddf2\ud83c\uddf1', calling_code: '223' },
    { iso: 'MT', name: 'Republic of Malta', flag: '\ud83c\uddf2\ud83c\uddf9', calling_code: '356' },
    { iso: 'MM', name: 'Republic of the Union of Myanmar', flag: '\ud83c\uddf2\ud83c\uddf2', calling_code: '95' },
    { iso: 'ME', name: 'Montenegro', flag: '\ud83c\uddf2\ud83c\uddea', calling_code: '382' },
    { iso: 'MN', name: 'Mongolia', flag: '\ud83c\uddf2\ud83c\uddf3', calling_code: '976' },
    { iso: 'MP', name: 'Commonwealth of the Northern Mariana Islands', flag: '\ud83c\uddf2\ud83c\uddf5', calling_code: '1670' },
    { iso: 'MZ', name: 'Republic of Mozambique', flag: '\ud83c\uddf2\ud83c\uddff', calling_code: '258' },
    { iso: 'MR', name: 'Islamic Republic of Mauritania', flag: '\ud83c\uddf2\ud83c\uddf7', calling_code: '222' },
    { iso: 'MU', name: 'Republic of Mauritius', flag: '\ud83c\uddf2\ud83c\uddfa', calling_code: '230' },
    { iso: 'MW', name: 'Republic of Malawi', flag: '\ud83c\uddf2\ud83c\uddfc', calling_code: '265' },
    { iso: 'MY', name: 'Malaysia', flag: '\ud83c\uddf2\ud83c\uddfe', calling_code: '60' },
    { iso: 'NA', name: 'Republic of Namibia', flag: '\ud83c\uddf3\ud83c\udde6', calling_code: '264' },
    { iso: 'NC', name: 'New Caledonia', flag: '\ud83c\uddf3\ud83c\udde8', calling_code: '687' },
    { iso: 'NE', name: 'Republic of Niger', flag: '\ud83c\uddf3\ud83c\uddea', calling_code: '227' },
    { iso: 'NF', name: 'Territory of Norfolk Island', flag: '\ud83c\uddf3\ud83c\uddeb', calling_code: '672' },
    { iso: 'NG', name: 'Federal Republic of Nigeria', flag: '\ud83c\uddf3\ud83c\uddec', calling_code: '234' },
    { iso: 'NI', name: 'Republic of Nicaragua', flag: '\ud83c\uddf3\ud83c\uddee', calling_code: '505' },
    { iso: 'NL', name: 'Kingdom of the Netherlands', flag: '\ud83c\uddf3\ud83c\uddf1', calling_code: '31' },
    { iso: 'NO', name: 'Kingdom of Norway', flag: '\ud83c\uddf3\ud83c\uddf4', calling_code: '47' },
    { iso: 'NP', name: 'Nepal', flag: '\ud83c\uddf3\ud83c\uddf5', calling_code: '977' },
    { iso: 'NR', name: 'Republic of Nauru', flag: '\ud83c\uddf3\ud83c\uddf7', calling_code: '674' },
    { iso: 'NZ', name: 'New Zealand', flag: '\ud83c\uddf3\ud83c\uddff', calling_code: '64' },
    { iso: 'OM', name: 'Sultanate of Oman', flag: '\ud83c\uddf4\ud83c\uddf2', calling_code: '968' },
    { iso: 'PK', name: 'Islamic Republic of Pakistan', flag: '\ud83c\uddf5\ud83c\uddf0', calling_code: '92' },
    { iso: 'PA', name: 'Republic of Panama', flag: '\ud83c\uddf5\ud83c\udde6', calling_code: '507' },
    { iso: 'PN', name: 'Pitcairn, Henderson, Ducie and Oeno Islands', flag: '\ud83c\uddf5\ud83c\uddf3', calling_code: '64' },
    { iso: 'PE', name: 'Republic of Peru', flag: '\ud83c\uddf5\ud83c\uddea', calling_code: '51' },
    { iso: 'PH', name: 'Republic of the Philippines', flag: '\ud83c\uddf5\ud83c\udded', calling_code: '63' },
    { iso: 'PW', name: 'Republic of Palau', flag: '\ud83c\uddf5\ud83c\uddfc', calling_code: '680' },
    { iso: 'PG', name: 'Independent State of Papua New Guinea', flag: '\ud83c\uddf5\ud83c\uddec', calling_code: '675' },
    { iso: 'PL', name: 'Republic of Poland', flag: '\ud83c\uddf5\ud83c\uddf1', calling_code: '48' },
    { iso: 'PR', name: 'Commonwealth of Puerto Rico', flag: '\ud83c\uddf5\ud83c\uddf7', calling_code: '1787' },
    { iso: 'KP', name: 'Democratic People\'s Republic of Korea', flag: '\ud83c\uddf0\ud83c\uddf5', calling_code: '850' },
    { iso: 'PT', name: 'Portuguese Republic', flag: '\ud83c\uddf5\ud83c\uddf9', calling_code: '351' },
    { iso: 'PY', name: 'Republic of Paraguay', flag: '\ud83c\uddf5\ud83c\uddfe', calling_code: '595' },
    { iso: 'PS', name: 'West Bank and Gaza', flag: '\ud83c\uddf5\ud83c\uddf8', calling_code: '970' },
    { iso: 'PF', name: 'French Polynesia', flag: '\ud83c\uddf5\ud83c\uddeb', calling_code: '689' },
    { iso: 'QA', name: 'State of Qatar', flag: '\ud83c\uddf6\ud83c\udde6', calling_code: '974' },
    { iso: 'RO', name: 'Romania', flag: '\ud83c\uddf7\ud83c\uddf4', calling_code: '40' },
    { iso: 'RU', name: 'Russian Federation', flag: '\ud83c\uddf7\ud83c\uddfa', calling_code: '7' },
    { iso: 'RW', name: 'Republic of Rwanda', flag: '\ud83c\uddf7\ud83c\uddfc', calling_code: '250' },
    { iso: 'SA', name: 'Kingdom of Saudi Arabia', flag: '\ud83c\uddf8\ud83c\udde6', calling_code: '966' },
    { iso: 'SD', name: 'Republic of the Sudan', flag: '\ud83c\uddf8\ud83c\udde9', calling_code: '249' },
    { iso: 'SN', name: 'Republic of Senegal', flag: '\ud83c\uddf8\ud83c\uddf3', calling_code: '221' },
    { iso: 'SG', name: 'Republic of Singapore', flag: '\ud83c\uddf8\ud83c\uddec', calling_code: '65' },
    { iso: 'GS', name: 'South Georgia and the Islands', flag: '\ud83c\uddec\ud83c\uddf8', calling_code: '500' },
    { iso: 'SL', name: 'Republic of Sierra Leone', flag: '\ud83c\uddf8\ud83c\uddf1', calling_code: '232' },
    { iso: 'SV', name: 'Republic of El Salvador', flag: '\ud83c\uddf8\ud83c\uddfb', calling_code: '503' },
    { iso: 'SM', name: 'Republic of San Marino', flag: '\ud83c\uddf8\ud83c\uddf2', calling_code: '378' },
    { iso: 'SO', name: 'Federal Republic of Somalia', flag: '\ud83c\uddf8\ud83c\uddf4', calling_code: '252' },
    { iso: 'PM', name: 'Saint Pierre and Miquelon', flag: '\ud83c\uddf5\ud83c\uddf2', calling_code: '508' },
    { iso: 'RS', name: 'Republic of Serbia', flag: '\ud83c\uddf7\ud83c\uddf8', calling_code: '381' },
    { iso: 'SS', name: 'Republic of South Sudan', flag: '\ud83c\uddf8\ud83c\uddf8', calling_code: '211' },
    { iso: 'ST', name: 'Democratic Republic of S\u00c3\u00a3o Tom\u00c3\u00a9 and Principe', flag: '\ud83c\uddf8\ud83c\uddf9', calling_code: '239' },
    { iso: 'SR', name: 'Republic of Suriname', flag: '\ud83c\uddf8\ud83c\uddf7', calling_code: '597' },
    { iso: 'SK', name: 'Slovak Republic', flag: '\ud83c\uddf8\ud83c\uddf0', calling_code: '421' },
    { iso: 'SI', name: 'Republic of Slovenia', flag: '\ud83c\uddf8\ud83c\uddee', calling_code: '386' },
    { iso: 'SE', name: 'Kingdom of Sweden', flag: '\ud83c\uddf8\ud83c\uddea', calling_code: '46' },
    { iso: 'SZ', name: 'Kingdom of eSwatini', flag: '\ud83c\uddf8\ud83c\uddff', calling_code: '268' },
    { iso: 'SX', name: 'Sint Maarten (Dutch part)', flag: '\ud83c\uddf8\ud83c\uddfd', calling_code: '1721' },
    { iso: 'SC', name: 'Republic of Seychelles', flag: '\ud83c\uddf8\ud83c\udde8', calling_code: '248' },
    { iso: 'SY', name: 'Syrian Arab Republic', flag: '\ud83c\uddf8\ud83c\uddfe', calling_code: '963' },
    { iso: 'TC', name: 'Turks and Caicos Islands', flag: '\ud83c\uddf9\ud83c\udde8', calling_code: '1649' },
    { iso: 'TD', name: 'Republic of Chad', flag: '\ud83c\uddf9\ud83c\udde9', calling_code: '235' },
    { iso: 'TG', name: 'Togolese Republic', flag: '\ud83c\uddf9\ud83c\uddec', calling_code: '228' },
    { iso: 'TH', name: 'Kingdom of Thailand', flag: '\ud83c\uddf9\ud83c\udded', calling_code: '66' },
    { iso: 'TJ', name: 'Republic of Tajikistan', flag: '\ud83c\uddf9\ud83c\uddef', calling_code: '992' },
    { iso: 'TM', name: 'Turkmenistan', flag: '\ud83c\uddf9\ud83c\uddf2', calling_code: '993' },
    { iso: 'TL', name: 'Democratic Republic of Timor-Leste', flag: '\ud83c\uddf9\ud83c\uddf1', calling_code: '670' },
    { iso: 'TO', name: 'Kingdom of Tonga', flag: '\ud83c\uddf9\ud83c\uddf4', calling_code: '676' },
    { iso: 'TT', name: 'Republic of Trinidad and Tobago', flag: '\ud83c\uddf9\ud83c\uddf9', calling_code: '1868' },
    { iso: 'TN', name: 'Republic of Tunisia', flag: '\ud83c\uddf9\ud83c\uddf3', calling_code: '216' },
    { iso: 'TR', name: 'Republic of Turkey', flag: '\ud83c\uddf9\ud83c\uddf7', calling_code: '90' },
    { iso: 'TV', name: 'Tuvalu', flag: '\ud83c\uddf9\ud83c\uddfb', calling_code: '688' },
    { iso: 'TZ', name: 'United Republic of Tanzania', flag: '\ud83c\uddf9\ud83c\uddff', calling_code: '255' },
    { iso: 'UG', name: 'Republic of Uganda', flag: '\ud83c\uddfa\ud83c\uddec', calling_code: '256' },
    { iso: 'UA', name: 'Ukraine', flag: '\ud83c\uddfa\ud83c\udde6', calling_code: '380' },
    { iso: 'UY', name: 'Oriental Republic of Uruguay', flag: '\ud83c\uddfa\ud83c\uddfe', calling_code: '598' },
    { iso: 'US', name: 'United States of America', flag: '\ud83c\uddfa\ud83c\uddf8', calling_code: '1' },
    { iso: 'UZ', name: 'Republic of Uzbekistan', flag: '\ud83c\uddfa\ud83c\uddff', calling_code: '998' },
    { iso: 'VA', name: 'State of the Vatican City', flag: '\ud83c\uddfb\ud83c\udde6', calling_code: '3906698' },
    { iso: 'VC', name: 'Saint Vincent and the Grenadines', flag: '\ud83c\uddfb\ud83c\udde8', calling_code: '1784' },
    { iso: 'VE', name: 'Bolivarian Republic of Venezuela', flag: '\ud83c\uddfb\ud83c\uddea', calling_code: '58' },
    { iso: 'VG', name: 'British Virgin Islands', flag: '\ud83c\uddfb\ud83c\uddec', calling_code: '1284' },
    { iso: 'VI', name: 'Virgin Islands of the United States', flag: '\ud83c\uddfb\ud83c\uddee', calling_code: '1340' },
    { iso: 'VN', name: 'Socialist Republic of Vietnam', flag: '\ud83c\uddfb\ud83c\uddf3', calling_code: '84' },
    { iso: 'VU', name: 'Republic of Vanuatu', flag: '\ud83c\uddfb\ud83c\uddfa', calling_code: '678' },
    { iso: 'WF', name: 'Wallis and Futuna Islands', flag: '\ud83c\uddfc\ud83c\uddeb', calling_code: '681' },
    { iso: 'WS', name: 'Independent State of Samoa', flag: '\ud83c\uddfc\ud83c\uddf8', calling_code: '685' },
    { iso: 'YE', name: 'Republic of Yemen', flag: '\ud83c\uddfe\ud83c\uddea', calling_code: '967' },
    { iso: 'ZA', name: 'Republic of South Africa', flag: '\ud83c\uddff\ud83c\udde6', calling_code: '27' },
    { iso: 'ZM', name: 'Republic of Zambia', flag: '\ud83c\uddff\ud83c\uddf2', calling_code: '260' },
    { iso: 'ZW', name: 'Republic of Zimbabwe', flag: '\ud83c\uddff\ud83c\uddfc', calling_code: '263' }
];

export const CountryMap = _.keyBy(CountryList, 'iso');
