export const locale = {
    lang: 'en',
    data: {
        footer: {
            version: 'Version',
            privacy: 'Privacy Policy',
            terms: 'Terms and Conditions'
        }
    }
};
