<div id="reset-password" fxLayout="column">
    <div
        id="reset-password-form-wrapper"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div
            id="reset-password-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
        >
            <div class="logo">
                <img src="assets/images/logos/LAKLGCC_logo_gold_ver.png" />
            </div>

            <div class="app-name">
                {{ environment.appName }}
            </div>

            <div class="title">
                {{ 'auth.reset_password.title' | translate }}
            </div>

            <form name="resetPasswordForm" [formGroup]="form" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>{{
                        'auth.reset_password.new_password1' | translate
                    }}</mat-label>
                    <input
                        matInput
                        type="password"
                        formControlName="new_password1"
                    />
                    <mat-icon matSuffix class="secondary-text"
                        >vpn_key</mat-icon
                    >
                    <mat-error
                        *ngIf="form.get('new_password1').hasError('required')"
                    >
                        {{ 'auth.password_required' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="form.get('new_password1').hasError('minlength')"
                    >
                        {{
                            'auth.reset_password.password_minlength' | translate
                        }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{
                        'auth.reset_password.new_password2' | translate
                    }}</mat-label>
                    <input
                        matInput
                        type="password"
                        formControlName="new_password2"
                    />
                    <mat-icon matSuffix class="secondary-text"
                        >vpn_key</mat-icon
                    >
                    <mat-error
                        *ngIf="form.get('new_password2').hasError('required')"
                    >
                        {{ 'auth.password_required' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            !form.get('new_password2').hasError('required') &&
                            form
                                .get('new_password2')
                                .hasError('passwordsNotMatching')
                        "
                    >
                        {{
                            'auth.reset_password.password_mismatch' | translate
                        }}
                    </mat-error>
                </mat-form-field>
            </form>

            <div
                class="login"
                fxLayout="column"
                fxLayoutAlign="start center"
                fxLayoutGap="16px"
            >
                <mat-bar-button
                    (click)="send()"
                    [options]="buttonOption"
                ></mat-bar-button>
                <a class="link" [routerLink]="'/auth/login'">{{
                    'auth.reset_password.go_back_login' | translate
                }}</a>
            </div>
        </div>
    </div>
</div>
