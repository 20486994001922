import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrintLayoutComponent } from './main/print-layout/print-layout.component';
import { MaintenanceCanLoadGuard } from './core/guards/maintenance-can-load.guard';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: 'auth',
        loadChildren: () =>
            import('./main/auth/auth.module').then((m) => m.AuthModule),
        canLoad: [],
    },
    {
        path: 'home',
        loadChildren: () =>
            import('./main/home/home.module').then((m) => m.HomeModule),
        canLoad: [],
    },
    {
        path: 'accounts',
        loadChildren: () =>
            import('./main/customer/customer.module').then(
                (m) => m.CustomerModule
            ),
        canLoad: [],
    },
    {
        path: 'driver-productivity-report',
        loadChildren: () =>
            import(
                './main/driver-productivity-report/driver-productivity-report.module'
            ).then((m) => m.DriverProductivityReportModule),
        canLoad: [],
    },
    {
        path: 'request',
        loadChildren: () =>
            import(
                './main/request/request.module'
            ).then((m) => m.RequestModule),
        canLoad: [],
    },
    {
        path: 'maintenance',
        loadChildren: () =>
            import('./main/maintenance/maintenance.module').then(
                (m) => m.MaintenanceModule
            ),
    },
    {
        path: 'request',
        loadChildren: () =>
            import('./main/request/request.module').then(
                (m) => m.RequestModule
            ),
    },
    {
        path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        children: [],
    },
    { path: '**', redirectTo: 'home' }, // catch any unfound route
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
