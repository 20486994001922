import {
    Directive,
    HostListener,
    OnInit,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    ElementRef
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { formatNumber } from '@angular/common';
import { environment } from '@env/environment';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as _ from 'lodash';

@Directive({
    selector: '[appCurrencyInputFormatter]'
})
export class CurrencyInputFormatterDirective implements OnInit, OnDestroy {

    private el: HTMLInputElement;
    private unsubscribeAll: Subject<any>;
    private isFocused = false;

    @Input() appCurrencyFormatter: string;

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private elementRef: ElementRef,
        private model: NgControl
    ) {
        this.el = this.elementRef.nativeElement;
        this.unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.el.setAttribute('type', 'text');
        this.format();
        this.model.control.valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(value => {
                if (!this.isFocused) {
                    this.format();
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    @HostListener('blur')
    blur(): void {
        this.el.setAttribute('type', 'text');
        this.isFocused = false;

        this.format();
    }

    @HostListener('focus')
    focus(): void {
        this.el.setAttribute('type', 'number');
        this.isFocused = true;
        this.el.value = this.model.control.value;
        this.el.select();
    }

    format(): void {
        this.el.value = formatNumber(
            this.model.control.value ? this.model.control.value : 0,
            this.locale,
            this.appCurrencyFormatter || environment.currencyFormatPipe
        );
    }

}
