import {
    Component,
    ViewEncapsulation,
    EventEmitter,
    Output,
    Input,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as en } from './../i18n/en';
import { locale as zh } from './../i18n/zh';
import { AuthService } from '@app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@app/core/models/user.model';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-profile-form',
    templateUrl: './profile-form.component.html',
    styleUrls: ['./profile-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProfileFormComponent {
    form: FormGroup;
    translations: string[];
    user: User = {};

    @Input()
    showSaveButton = false;
    @Output()
    saved = new EventEmitter<User>();

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translateService: TranslateService,
        public snackBar: MatSnackBar
    ) {
        this.fuseTranslationLoaderService.loadTranslations(en, zh);

        this.translateService
            .get(['auth.edit_profile.success_update'])
            .subscribe((translations) => {
                this.translations = translations;
            });

        this.user = this.authService.currentUser();

        this.form = this.formBuilder.group({
            email: [{ value: this.user.email, disabled: true }],
            fullname: [this.user.fullname, Validators.required],
            ic: [this.user.ic, Validators.required],
            calling_iso: [this.user.calling_iso, Validators.required],
            calling_code: [this.user.calling_code, Validators.required],
            calling_phone: [
                this.user.calling_phone,
                [Validators.required, Validators.pattern(/^\d+$/)],
            ],
        });
    }

    save(): void {
        this.authService
            .updateProfile(Object.assign({}, this.user, this.form.value))
            .subscribe(
                (data) => {
                    this.showSuccessMessage(
                        this.translations['auth.edit_profile.success_update']
                    );
                    this.saved.next(data);
                },
                (error) => this.showErrorMessage(error.parsedMessage)
            );
    }

    private showSuccessMessage(message: string): void {
        this.snackBar.open(message, '', {
            duration: 3000,
        });
    }

    private showErrorMessage(error: string): void {
        this.snackBar.open(error, '', {
            duration: 3000,
            panelClass: ['warn'],
        });
    }
}
