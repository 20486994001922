export interface User {
    id?: number;    
    email?: string;
    password?: string;
    fullname?: string;
    username?: string;
    role?: string;
    ic?: string;
    calling_iso?: string;
    calling_code?: string;
    calling_phone?: string;
    /* is_active?: boolean; */
    create_at?: Date;
    modify_at?: Date;
    edit_by?: number;
    feature_access?: string[];    
    date_joined?: string;
    department_user?: department_user[];
    is_superuser?: boolean;
    is_verify?: boolean;
    contact_display?: string;
}

export interface department_user {
    department?: number;
    role?: string;
}

export enum USER_ROLE {
    ROLE_MANAGER = 'manager',
    ROLE_EXECUTIVE = 'executive',
    ROLE_CUSTOMER = 'customer',
    /* ROLE_DRIVER = 'driver' */
}

export const USER_ROLE_OPTIONS = new Map<string, any>([
    [
        USER_ROLE.ROLE_MANAGER,
        {
            title: 'user_role_options.manager',
            background: 'primary-300-bg',
        },
    ],
    [
        USER_ROLE.ROLE_EXECUTIVE,
        {
            title: 'user_role_options.executive',
            background: 'indigo-300-bg',
        },
    ],
    [
        USER_ROLE.ROLE_CUSTOMER,
        {
            title: 'user_role_options.customer',
            background: 'blue-300-bg',
        },
    ],
    /* [
        USER_ROLE.ROLE_DRIVER,
        {
            title: 'user_role_options.driver',
            background: 'green-300-bg',
        },
    ] */
]);

export enum CUSTOMER_TYPE {
    CUSTOMER_EMPLOYEE = 'employee',
    CUSTOMER_PRIME = 'prime',
    CUSTOMER_EARLY_BIRD = 'earlybird',
    CUSTOMER_PUBLIC = 'public',
}

export const CUSTOMER_TYPE_OPTIONS = new Map<string, any>([
    [
        CUSTOMER_TYPE.CUSTOMER_EMPLOYEE,
        {
            title: 'customer_type_options.employee',
            background: 'employee-bg',
            color: '#52B9D7',
        },
    ],
    [
        CUSTOMER_TYPE.CUSTOMER_PRIME,
        {
            title: 'customer_type_options.prime',
            background: 'prime-bg',
            color: '#DB5B9B',
        },
    ],
    [
        CUSTOMER_TYPE.CUSTOMER_EARLY_BIRD,
        {
            title: 'customer_type_options.early_bird',
            background: 'early-bird-bg',
            color: '#4A82BB',
        },
    ],
    [
        CUSTOMER_TYPE.CUSTOMER_PUBLIC,
        {
            title: 'customer_type_options.public',
            background: 'public-bg',
            color: '#5C5F92',
        },
    ],
]);

export enum BUMIPUTRA_STATUS {
    BUMIPUTRA = 'bumiputra',
    NON_BUMIPUTRA = 'non-bumiputra',
    UNSPECIFIED_BUMIPUTRA = 'unspecified',
}

export const BUMIPUTRA_STATUS_OPTIONS = new Map<string, any>([
    [
        BUMIPUTRA_STATUS.BUMIPUTRA,
        {
            title: 'bumiputra_status_options.bumiputra',
            background: 'blue-300-bg',
        },
    ],
    [
        BUMIPUTRA_STATUS.NON_BUMIPUTRA,
        {
            title: 'bumiputra_status_options.non_bumiputra',
            background: 'green-300-bg',
        },
    ],
    [
        BUMIPUTRA_STATUS.UNSPECIFIED_BUMIPUTRA,
        {
            title: 'bumiputra_status_options.unspecified',
            background: 'fuse-navy-300',
        },
    ],
]);

export const CallingCodeOptions = [
    {
        iso: 'MY',
        code: '60',
    },
    {
        iso: 'ID',
        code: '62',
    },
    {
        iso: 'SG',
        code: '65',
    },
    {
        iso: 'TH',
        code: '66',
    },
    {
        iso: 'VN',
        code: '84',
    },
];
