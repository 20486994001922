import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { environment } from '@env/environment';

import { AppRoutingModule } from '@app/app-routing.module';
import { FuseCardModule } from '@fuse/components/card';
import { MAT_DATE_FORMATS, ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { PrintLayoutComponent } from './main/print-layout/print-layout.component';
import { FakeDbService } from './fake-db/fake-db.service';

import { NgxMatDateFormats, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export const DATE_PICKER_FORMATS = {
    parse: {
        dateInput: environment.dateFormatPicker
    },
    display: {
        dateInput: environment.dateFormatPicker,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

const DATE_TIME_PICKER_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: environment.dateTimeFormatPicker
    },
    display: {
        dateInput: environment.dateTimeFormatPicker,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@NgModule({
    declarations: [
        AppComponent,
        PrintLayoutComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'csrftoken',
            headerName: 'X-CSRFToken',
        }),
        TranslateModule.forRoot(),

        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,
        NgxMatMomentModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatCardModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FuseCardModule,

        // App modules
        LayoutModule,

        CoreModule, // Singleton objects (services, components that are loaded only once, etc.)
        SharedModule, // Shared (multi-instance) objects
        AppRoutingModule,

        // AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,

        FormsModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { float: 'always' } },
        { provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMATS },
        { provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_PICKER_FORMATS },
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }
    ]
})
export class AppModule {
}
