import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class JWTService {
    rememberMe = false;
    loggedIn = false;

    private readySubject = new ReplaySubject<boolean>(1);
    public ready$ = this.readySubject.asObservable();

    constructor() {
        this.rememberMe = localStorage['remember_me']
            ? JSON.parse(localStorage['remember_me'])
            : false;

        this.loggedIn = localStorage['logged_in']
            ? JSON.parse(localStorage['logged_in'])
            : false;

        if (!this.rememberMe) {
            window.addEventListener('storage', event => {
                // console.log('storage listener', event);

                if (event.key === 'getSessionStorage') {
                    // console.log(
                    //     'Some tab asked for the sessionStorage -> send it'
                    // );

                    localStorage.setItem(
                        'sessionStorage',
                        JSON.stringify(sessionStorage)
                    );
                    localStorage.removeItem('sessionStorage');
                } else if (event.key === 'sessionStorage') {
                    if (!sessionStorage.length) {
                        // console.log('Another tab sent data <- get it');
                        const data = JSON.parse(event.newValue);
                        for (const key in data) {
                            if (data.hasOwnProperty(key)) {
                                sessionStorage.setItem(key, data[key]);
                            }
                        }
                    } else if (event.oldValue && !event.newValue) {
                        // console.log('Done getting data');
                        this.readySubject.next();
                        this.readySubject.complete();
                    }
                } else if (event.key === 'logged_in' && !event.newValue) {
                    sessionStorage.removeItem('token');
                }
            });

            if (this.loggedIn && !sessionStorage.length) {
                // console.log('Ask other tabs for session storage');

                localStorage.setItem(
                    'getSessionStorage',
                    Date.now().toString()
                );
                localStorage.removeItem('getSessionStorage');

                // If no opened tabs reponse me, timeout.
                setTimeout(() => {
                    if (!this.readySubject.isStopped) {
                        // console.log('Timeout');
                        this.readySubject.next();
                        this.readySubject.complete();
                    }
                }, 1000);
            } else {
                this.readySubject.next();
                this.readySubject.complete();
            }
        } else {
            this.readySubject.next();
            this.readySubject.complete();
        }

        // this.readySubject.next();
        // this.readySubject.complete();
    }

    saveRememberMe(rememberMe: boolean): void {
        this.rememberMe = rememberMe;
        window.localStorage['remember_me'] = JSON.stringify(rememberMe);
    }

    getToken(): string {
        if (this.rememberMe) {
            return window.localStorage['token'];
        } else {
            return window.sessionStorage['token'];
        }
    }

    saveToken(token: string) {
        if (this.rememberMe) {
            localStorage['token'] = token;
        } else {
            sessionStorage['token'] = token;
        }

        this.saveLoggedIn(token ? true : false);
    }

    saveLoggedIn(loggedIn: boolean): void {
        this.loggedIn = loggedIn;
        window.localStorage['logged_in'] = JSON.stringify(loggedIn);
    }

    destroyToken() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        localStorage.removeItem('logged_in');
    }
}
