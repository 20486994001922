import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { APIService } from './api.service';
import { Observable } from 'rxjs';
import { SkipTokenInterceptor } from '../interceptors/token.interceptor';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Injectable()
export class MaintenanceService {

    constructor(
        private apiService: APIService
    ) { }

    get(): Observable<any> {
        const headers = new HttpHeaders().set(SkipTokenInterceptor, '');

        return this.apiService.get(`/status/maintenance`, { headers });
    }

    isDown(): Observable<boolean> {
        const headers = new HttpHeaders().set(SkipTokenInterceptor, '');

        return this.apiService.get(`/status/maintenance`, { headers })
            .pipe(map(data => !_.isEmpty(data)));
    }

    isUp(): Observable<boolean> {
        const headers = new HttpHeaders().set(SkipTokenInterceptor, '');

        return this.apiService.get(`/status/maintenance`, { headers })
            .pipe(map(data => _.isEmpty(data)));
    }
}
