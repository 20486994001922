import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appReadyButton]'
})
export class ReadyButtonDirective implements OnChanges {
    @Input('appReadyButton')
    uid: any;

    @Input()
    shouldReady: any[] = [];

    constructor(private el: ElementRef) { }

    @HostListener('click') onClick(): void {
        this.active();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.shouldReady.includes(this.uid)) {
            this.ready();
        }
    }

    private active(): void {
        this.el.nativeElement.disabled = true;
    }

    private ready(): void {
        this.el.nativeElement.disabled = false;
    }
}
