import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as en } from './i18n/en';
import { locale as zh } from './i18n/zh';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
    constructor(
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        public matDialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.fuseTranslationLoaderService.loadTranslations(en, zh);
    }

    ngOnInit(): void { }
}
