<div class="dialog-content-wrapper">
    <mat-toolbar class="fuse-white m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ 'auth.edit_profile.title' | translate }}</span>
            <button color="primary" mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <app-profile-form #formComponent (saved)="saved($event)"></app-profile-form>
    </div>

    <div mat-dialog-actions class="m-0 p-24" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="24px">
        <button mat-stroked-button type="button" color="primary" (click)="matDialogRef.close()" aria-label="Cancel">
            {{ 'auth.edit_profile.cancel' | translate }}
        </button>

        <button mat-flat-button color="accent" (click)="formComponent.save()"
            [disabled]="formComponent.form.pristine || formComponent.form.invalid" aria-label="Save">
            {{ 'auth.edit_profile.save' | translate }}
        </button>
    </div>
</div>