import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { MaintenanceService } from '../services/maintenance.service';
import { Observable } from 'rxjs';

@Injectable()
export class MaintenanceCanActivateGuard implements CanActivate {
    constructor(
        private router: Router,
        private maintenanceService: MaintenanceService) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.maintenanceService.isUp().pipe(map(result => {
            const canActivate = result;
            // console.log('MaintenanceCanActivateGuard', 'canActivate', canActivate);
            if (!canActivate) {
                this.router.navigate(['/maintenance']);
            }
            return canActivate;
        }));
    }
}
