import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { APIService } from './api.service';
import { Deparment } from '../models/department.model';
import { Observable, of } from 'rxjs';
import { IQuery } from '../models/query.model';
import { map } from 'rxjs/operators';

@Injectable()
export class DepartmentService extends BaseService<Deparment> {
    constructor(public apiService: APIService) {
        super(apiService, 'departments', 'departments', '/departments');
    }

    listingByMe(query: IQuery = {}): Observable<any> {
        const params = this.getHttpParams(query);
        return this.apiService.get(`${this.resourceURL}?me`, { params })
            .pipe(map(data => data[this.single] || data));
    }

    
}
