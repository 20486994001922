<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <a routerLink="/" mat-button fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <span class="s-16 mr-sm-4">{{ 'footer.version' | translate }}</span>
            <span>{{ version }}-prod</span>
        </a>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button routerLink="/">{{ 'footer.privacy' | translate }}</a>
            <span>&bull;</span>
            <a mat-button routerLink="/">{{ 'footer.terms' | translate }}</a>
        </div>


    </div>

</mat-toolbar>