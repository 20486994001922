import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as en } from './../i18n/en';
import { locale as zh } from './../i18n/zh';

import { AuthService } from '@app/core/services/auth.service';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { takeUntil, debounceTime, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    environment = environment;
    form: FormGroup;

    buttonOption: MatProgressButtonOptions = {
        active: false,
        text: '',
        buttonColor: 'accent',
        barColor: 'accent',
        raised: true,
        stroked: false,
        mode: 'indeterminate',
        value: 0,
        disabled: true
    };

    translations: string[];

    private unsubscribeAll: Subject<any>;

    constructor(
        private fuseConfigService: FuseConfigService,
        private formBuilder: FormBuilder,
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translateService: TranslateService,
        private authService: AuthService,
        public snackBar: MatSnackBar
    ) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.fuseTranslationLoaderService.loadTranslations(en, zh);
        this.unsubscribeAll = new Subject();

        this.translateService
            .get([
                'auth.forgot_password.send_reset_link_button',
                'auth.forgot_password.success_send'
            ])
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(translations => {
                this.translations = translations;
                this.buttonOption.text = translations['auth.forgot_password.send_reset_link_button'];
            });

        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    ngOnInit(): void {
        this.form.valueChanges
            .pipe(
                takeUntil(this.unsubscribeAll),
                debounceTime(150),
                tap(
                    () =>
                        (this.buttonOption.disabled =
                            this.form.pristine ||
                            this.form.invalid)
                )
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    send(): void {
        if (!this.buttonOption.disabled && !this.buttonOption.active) {
            this.buttonOption.active = true;

            this.authService.resetPassword(this.form.value)
                .subscribe(
                    data => this.showSuccessMessage(this.translations['auth.forgot_password.success_send']),
                    error => this.showErrorMessage(error.parsedMessage)
                );
        }
    }

    private showSuccessMessage(message: string): void {
        this.snackBar.open(message, '', {
            duration: 3000
        });

        this.buttonOption.active = false;
    }

    showErrorMessage(error): void {
        this.snackBar.open(error, '', {
            duration: 3000,
            panelClass: ['warn']
        });

        this.buttonOption.active = false;
    }

}
