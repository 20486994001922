export const locale = {
    lang: 'zh',
    data: {
        footer: {
            version: '系统版本',
            privacy: '隐私政策',
            terms: '使用条款'
        }
    }
};
