import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as en } from './../i18n/en';
import { locale as zh } from './../i18n/zh';
import { AuthService } from '@app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { confirmPasswordValidator } from '@app/shared/validators/confirm-password.validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent {
    form: FormGroup;
    translations: string[];

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translateService: TranslateService,
        public matDialogRef: MatDialogRef<ChangePasswordComponent>,
        public snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.fuseTranslationLoaderService.loadTranslations(en, zh);

        this.translateService
            .get([
                'auth.change_password.success_update',
                'auth.change_password.invalid_old_password'
            ])
            .subscribe(translations => {
                this.translations = translations;
            });


        this.form = this.formBuilder.group({
            old_password: ['', Validators.required],
            new_password1: ['', [Validators.required, Validators.minLength(6)]],
            new_password2: ['', [Validators.required, confirmPasswordValidator]]
        });
    }

    save(): void {
        this.authService
            .changePassword(this.form.value)
            .subscribe(
                data => {
                    this.showSuccessMessage(this.translations['auth.change_password.success_update']);
                    this.matDialogRef.close();
                },
                error => this.showErrorMessage(error.parsedMessage)
            );
    }

    private showSuccessMessage(message: string): void {
        this.snackBar.open(message, '', {
            duration: 3000
        });
    }

    private showErrorMessage(error: string): void {
        if (error.includes('old_password')) {
            error = this.translations['auth.change_password.invalid_old_password'];
        }

        this.snackBar.open(error, '', {
            duration: 3000,
            panelClass: ['warn']
        });
    }
}
