<mat-form-field appearance="outline" class="w-100-p">
    <mat-label>{{ 'international_phone_input.calling_code' | translate }}</mat-label>
    <mat-select [formControl]="isoFormControl">
        <mat-select-trigger>
            <div *ngIf="CountryMap[isoFormControl.value] as selectedCountry">
                <span>{{ selectedCountry.flag }}</span>
                <span>{{ selectedCountry.name }}</span>
                <span class="ml-8 secondary-text">+{{ selectedCountry.calling_code }}</span>
            </div>
        </mat-select-trigger>

        <mat-option [formGroup]="countryFilterForm">
            <ngx-mat-select-search *ngIf="!readonly" [placeholderLabel]="
                    'international_phone_input.search_country' | translate
                " [noEntriesFoundLabel]="
                    'international_phone_input.no_result' | translate
                " [clearSearchInput]="true" formControlName="filter"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let option of countryOptions$ | async" [value]="option.iso">
            <div>
                <span>{{ option.flag }}</span>
                <span>{{ option.name }}</span>
                <span class="ml-8 secondary-text">+{{ option.calling_code }}</span>
            </div>
        </mat-option>
    </mat-select>
</mat-form-field>