import { Injectable } from '@angular/core';
import { Router, UrlTree, CanLoad } from '@angular/router';
import { map } from 'rxjs/operators';
import { MaintenanceService } from '../services/maintenance.service';
import { Observable } from 'rxjs';

@Injectable()
export class MaintenanceCanLoadGuard implements CanLoad {
    constructor(
        private router: Router,
        private maintenanceService: MaintenanceService) { }

    canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.maintenanceService.isUp().pipe(map(result => {
            const canActivate = result;
            // console.log('MaintenanceCanLoadGuard', 'canActivate', canActivate);
            if (!canActivate) {
                this.router.navigate(['/maintenance']);
            }
            return canActivate;
        }));
    }
}
