import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { JWTService } from '../services/jwt.service';
import { Observable } from 'rxjs';

export const SkipTokenInterceptor = 'SkipTokenInterceptor';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private jwtService: JWTService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        if (request.headers.has(SkipTokenInterceptor)) {
            request = request.clone({ headers: request.headers.delete(SkipTokenInterceptor) });

        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.jwtService.getToken()}`
                }
            });
        }

        return next.handle(request);
    }
}
