export const locale = {
    lang: 'en',
    data: {
        auth: {
            email: 'Email',
            password: 'Password',
            email_required: 'Email is required',
            enter_valid_email: 'Please enter a valid email address',
            password_required: 'Password is required',
            login: {
                title: 'LOGIN TO YOUR ACCOUNT',
                remember_me: 'Remember Me',
                forgot_password: 'Forgot Password?',
                login_button: 'LOGIN'
            },
            forgot_password: {
                title: 'RECOVER YOUR PASSWORD',
                send_reset_link_button: 'SEND RESET LINK',
                go_back_login: 'Go back to login',
                success_send: 'Password reset e-mail has been sent'
            },
            reset_password: {
                title: 'RESET YOUR PASSWORD',
                new_password1: 'New Password',
                new_password2: 'Confirm New Password',
                password_minlength: 'Min 6 characters',
                password_mismatch: 'Password Mismatch',
                send: 'RESET PASSWORD',
                go_back_login: 'Go back to login',
                success_send: 'Password successfully reset',
                invalid_token_or_uid: 'Password failed to reset'
            },
            change_password: {
                title: 'Change Password',
                old_password: 'Old Password',
                new_password1: 'New Password',
                new_password2: 'Confirm New Password',
                password_minlength: 'Min 6 characters',
                password_mismatch: 'Password Mismatch',
                save: 'UPDATE PASSWORD',
                cancel: 'CANCEL',
                success_update: 'Password successfully updated',
                invalid_old_password: 'Invalid Old Password'
            },
            edit_profile: {
                title: 'Edit Profile',
                first_name: 'First Name',
                last_name: 'Last Name',
                first_name_required: 'First Name is required',
                fullname: 'Full Name',
                fullname_required: 'Full Name is required',
                save: 'UPDATE PROFILE',
                cancel: 'CANCEL',
                success_update: 'Profile successfully updated',
                calling_code: 'Country Code',
                contact: 'Contact Number',
                ic: 'IC / Passport Number',
                ic_hint: 'e.g. 801010-07-1234 / A20200522',
                ic_length_hint: 'Length is 12',
                ic_format: 'Please enter a valid IC number',
                contact_hint: 'e.g. 163728908',
                valid_phone_no_hint: 'Please enter a valid phone number',
                required: 'Required',
                search_country: 'Search Country',
                no_result: 'No Result'
            },
            complete_profile: {
                title: 'EDIT PROFILE',
                save: 'SAVE'
            },
            activate_account: {
                title: 'ACTIVATE YOUR ACCOUNT',
                activate: 'ACTIVATE',
                success_activate: 'Account successfully activate',
                expired: 'Activation is Expired',
                go_back_login: 'Go back to login',
            }
        }
    }
};
