export class TransferOrderFakeDb {
    public static transferOrders =
        [
            {
                'id': 1,
                'name': 'TO#1',
                'lines': [],
                'packing_orders': [],
                'status': 'packing',
                'status_transitions': [
                    'fulfilled'
                ],
                'source_location': 1,
                'target_location': 1,
                'remark': null,
                'confirm_by': null,
                'total_price': 0,
                'create_at': '2020-01-13T18:57:21.173743Z',
                'modify_at': '2020-01-15T03:25:05.715243Z',
                'edit_by': 1,
                'links': {
                    'lines': 'lines/',
                    'packing_orders': 'packing_orders/'
                }
            }
        ];

    public static transferOrderLines =
        [
            {
                'id': 1,
                'transfer_order': 1,
                'stock': 1,
                'quantity': 1.0,
                'unit_price': '292.5000',
                'total_price': '292.5000',
                'create_at': '2020-01-13T18:57:26.832844Z',
                'modify_at': '2020-01-13T18:57:26.918010Z',
                'edit_by': 1
            },
        ];
}
