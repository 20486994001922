import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { environment } from '@env/environment';

@Pipe({
    name: 'currencyFormat'
})
export class CurrencyFormatPipe extends DecimalPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        return super.transform(value, environment.currencyFormatPipe);
    }

}
