import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as en } from './../i18n/en';
import { locale as zh } from './../i18n/zh';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EditProfileComponent {
    form: FormGroup;
    translations: string[];

    constructor(
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        public matDialogRef: MatDialogRef<EditProfileComponent>,) {

        this.fuseTranslationLoaderService.loadTranslations(en, zh);
    }

    saved(event): void {
        this.matDialogRef.close();
    }
}
