export const locale = {
    lang: 'zh',
    data: {
        international_phone_input: {
            calling_code: 'Country Code',
            contact: 'Contact Number',
            search_country: 'Search Country',
            no_result: 'No Result'
        }
    }
};
