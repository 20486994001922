import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { JWTService } from '../services/jwt.service';
import { Observable } from 'rxjs';

@Injectable()
export class FeatureAccessGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private jwtService: JWTService) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const feature = route.data['feature'];

        return this.authService.isAuthenticated$.pipe(map(isAuthenticated => {
            const user = this.authService.currentUser();
            const canActivate = !!(user.feature_access && user.feature_access.includes(feature));
            // console.log('FeatureAccessGuard', feature, 'canActivate', canActivate);
            if (!canActivate) {
                this.router.navigate(['/auth/login']);
            }
            return canActivate;
        }));
    }
}
