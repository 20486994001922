import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { APIService } from './api.service';
import { CaseType } from '../models/case_type.model';
import { Observable, of } from 'rxjs';
import { IQuery } from '../models/query.model';

@Injectable()
export class CaseTypeService extends BaseService<CaseType> {
    constructor(public apiService: APIService) {
        super(apiService, 'case-types', 'case-types', '/case-types');
    }

    
}
