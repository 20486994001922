import { InMemoryDbService } from 'angular-in-memory-web-api';
import { TransferOrderFakeDb } from './transfer-order';

export class FakeDbService implements InMemoryDbService {
    createDb(): any {
        return {
            // Transfer Orders
            'transfer-orders': TransferOrderFakeDb.transferOrders,
            'transfer-order-lines': TransferOrderFakeDb.transferOrderLines
        };
    }
}
