import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Injectable()
export class PrintService {
    isPrinting = false;

    constructor(private router: Router) { }

    print(commands: any[], extras?: NavigationExtras): void {
        this.isPrinting = true;
        this.router.navigate([
            {
                outlets: {
                    'print': commands
                }
            }], { skipLocationChange: true });
    }

    ready(): void {
        setTimeout(() => {
            window.print();
            this.isPrinting = false;
            this.router.navigate([{ outlets: { print: null } }], { skipLocationChange: true });
        });
    }
}
