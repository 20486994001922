export const locale = {
    lang: 'en',
    data: {
        nav: {
            home: 'Home',
            dashboard: 'Dashboard',
            user: 'User Management',
            request: 'Requests',
            account: 'Accounts Management'
        },
    },
};
