import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { APIService } from './services/api.service';
import { JWTService } from './services/jwt.service';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { MaintenanceCanActivateGuard } from './guards/maintenance-can-activate.guard';
import { MaintenanceCanLoadGuard } from './guards/maintenance-can-load.guard';
import { HttpRequestInterceptor } from './interceptors/http.request.interceptor';
import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { UserService } from './services/user.service';
import { FeatureAccessGuard } from './guards/feature-access.guard';
import { PrintService } from './services/print.service';
import { FeatureService } from './services/feature.service';
import { FeatureAccessService } from './services/feature-access.service';
import { MaintenanceService } from './services/maintenance.service';
import { ReportService } from './services/report.service';
import { RequestService } from './services/request.service';
import { CaseTypeService } from './services/case_type.service';
import { CaseRemarkService } from './services/case_remark.service';
import { DepartmentService } from './services/department.service';
import { ContactsService } from './services/contacts.service';
import { AccountsService } from './services/accounts.service';
import { WebformAPIService } from './services/webform_api.service';

@NgModule({
    imports: [],
    declarations: [],
    providers: [
        JWTService,
        APIService,
        AuthService,
        UserService,
        PrintService,
        FeatureService,
        FeatureAccessService,
        CanDeactivateGuard,
        AuthGuard,
        FeatureAccessGuard,
        MaintenanceCanActivateGuard,
        MaintenanceCanLoadGuard,
        MaintenanceService,
        ReportService,
        RequestService,
        CaseTypeService,   
        CaseRemarkService,
        DepartmentService,
        ContactsService,  
        AccountsService,  
        WebformAPIService, 
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true,
        },
    ], // these should be singleton
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
    // Ensure that CoreModule is only loaded into AppModule

    // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        super(parentModule);
    }
}
